import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsImage } from "react-icons/bs";
import axios from '../AxiosConfig';
import config from "../../config";

const Upload = (props) => {

  console.log("Upload", props.art);
  
  // const [photos, setPhotos] = useState([]);
  
  // const getPhotos = async () => {
  //   const response = await axios.get(`galerie/fotos?bundesland=${config.BUNDESLAND}&art=${props.art}`);
  //   console.log("getPhotos", response.data);
  //   if (response.data.length > 0) {
  //     setPhotos(response.data.map(row => row.foto));
  //   } else {
  //     setPhotos([]);
  //   }
  // };

  // useEffect(() => {
  //   getPhotos();
  // }, []);

  const [modalStatus, setModalStatus] = useState(false);

  console.log("Upload", props.selectedPhotos);

  const openModal = () => setModalStatus(true);
  const closeModal = () => setModalStatus(false);

  const togglePhotoSelection = (photo) => {
    props.setSelectedPhotos((prevSelected) => {
      if (prevSelected.includes(photo)) {
        return prevSelected.filter(item => item !== photo);
      } else {
        return [...prevSelected, photo];
      }
    });
  };

  const handleConfirm = () => {
    console.log("Selected photos:", props.selectedPhotos);
    // Add the selected images to the images array
    const imagesNew = Array.from(new Set([...props.items, ...props.selectedPhotos]));
    props.setItems(imagesNew);
    closeModal();
  };

  return (
    <>
      {props.art !== null && (
        <Button onClick={openModal} variant="success">
          <BsImage className="text-2xl" /> Fotos hinzufügen
        </Button>
      )}

      <Modal show={modalStatus} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Fotos auswählen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="photo-grid">
            {props.photos.map((photo, index) => (
              <div
                key={index}
                className={`photo-item ${props.selectedPhotos.includes(photo) ? 'selected' : ''}`}
                onClick={() => togglePhotoSelection(photo)}
              >
                <img src={photo} alt={`Photo ${index}`} />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Schließen
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Bestätigen
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .photo-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
        .photo-item {
          width: 100px; /* Adjust as needed */
          cursor: pointer;
          border: 2px solid transparent; /* For visual feedback */
        }
        .photo-item.selected {
          border-color: blue; /* Highlight selected items */
        }
        .photo-item img {
          width: 100%;
          height: auto;
          display: block;
        }
      `}</style>
    </>
  );
};

export default Upload;
