import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import Grid from './layouts/Grid';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Upload from './Upload';

// const data = [
//   "https://storage.googleapis.com/schmetterlingebayern/net24-20201003213945-2.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/net24-20201003213945-1.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/net24-20201003223638-1.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/e343e0d6-990a-4c25-b204-d2f8c01d5ef4-1.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/f354cdb1-4a7f-4f46-892c-beb5b54b360f-1.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/net24-20201003221143-1.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/markusdumke-20200105173810-3.jpg"
// ];

function SortableItem({ id, url, index, isSelected, onSelect }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img src={url} style={{ width: '300px', height: '200px', objectFit: 'cover' }} />
      {/* <Button
        onClick={() => {
          console.log("onClick", id)
        }}
        style={{ position: "absolute", top: "10px", right: "10px", backgroundColor: "white", border: "none" }}
      >
        <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
      </Button> */}
      <input
        type="checkbox"
        checked={isSelected}
        onChange={(e) => {
          console.log("onChange", id)
          e.stopPropagation();
          onSelect(id);
        }}
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          backgroundColor: isSelected ? "#0dcaf0" : "white",
        }}
      />
    </div>
  );
}

function Gallery(props) {
  // const [items, setItems] = useState(data);
  // const [selectedItems, setSelectedItems] = useState([]);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
  );

  console.log(props.items)
  console.log(props.selectedItems)

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      props.setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleSelect = (id) => {
    console.log("handleSelect", id)
    props.setSelectedItems((selectedItems) =>
      selectedItems.includes(id)
        ? selectedItems.filter((item) => item !== id)
        : [...selectedItems, id]
    );
  };

  const handleDeleteSelected = () => {
    props.setItems((items) => items.filter((item) => !props.selectedItems.includes(item)));
    props.setSelectedItems([]);
  };

  // const handleAddImage = () => {
  //   const newImage = "https://storage.googleapis.com/schmetterlingebayern/47553007-8bcb-4da5-8857-05e1fe179f8a-1.jpg";
  //   // Check if the image already exists in the items array
  //   if (!props.items.includes(newImage)) {
  //     props.setItems([...props.items, newImage]);
  //   }
  // };

  console.log(props.art);

  return (
    <>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <Upload
          art={props.art}
          length={props.items.length === 0 && true}
          items={props.items}
          setItems={props.setItems}
          photos={props.photos}
          setPhotos={props.setPhotos}
          selectedPhotos={props.selectedPhotos}
          setSelectedPhotos={props.setSelectedPhotos}
        />
        {/* <Button
          onClick={handleAddImage}
          variant='success'
        >
          <FontAwesomeIcon icon={faImage} /> Bild hinzufügen
        </Button> */}
        {props.art !== null && (
          <Button
            onClick={handleDeleteSelected}
            variant='danger'
            disabled={props.selectedItems.length === 0}
          >
            <FontAwesomeIcon icon={faTrash} /> Ausgewählte Fotos entfernen
          </Button>
        )}
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        // onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      // onDragCancel={handleDragCancel}
      >
        <SortableContext items={props.items} strategy={rectSortingStrategy}>
          {/* call the grid layout for all the images */}
          <Grid>
            {/* mapping all data from items */}
            {props.items.map((url, index) => (
              <SortableItem
                key={url}
                id={url}
                url={url}
                index={index}
                isSelected={props.selectedItems.includes(url)}
                onSelect={handleSelect}
              />
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
    </>
  );
}

export default Gallery;
