import React, { useState } from "react";
import InputArt from './Inputs/InputArt.js';
import { Formik } from 'formik';
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { toast } from 'react-hot-toast';
import axios from "./AxiosConfig.js";
// import Fotogalerie from "./Beobachtungen/Fotogalerie.js";
import config from '../config/index.js';
// import Container from "./Galerie/layouts/Container";
// import DraggableArea from "./Galerie1/DraggableArea.jsx";
import Gallery from "./Galerie/Gallery.jsx";

// const data = [
//   "https://storage.googleapis.com/schmetterlingebayern/net24-20201003213945-2.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/net24-20201003213945-1.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/net24-20201003223638-1.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/e343e0d6-990a-4c25-b204-d2f8c01d5ef4-1.jpg",
//   "https://storage.googleapis.com/schmetterlingebayern/f354cdb1-4a7f-4f46-892c-beb5b54b360f-1.jpg"
// ];

export default function GalerieAdmin() {

  // const [images, setImages] = useState([]);
  const [art, setArt] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);

  const getPhotos = async (art) => {
    const response = await axios.get(`galerie/fotos?bundesland=${config.BUNDESLAND}&art=${art}`);
    console.log("getPhotos", response.data);
    if (response.data.length > 0) {
      setPhotos(response.data.map(row => row.foto));
    } else {
      setPhotos([]);
    }
  };

  const getImages = async (art) => {
    const response = await axios.get(`galerie?art=${art}&bundesland=${config.BUNDESLAND}`);
    setItems(response.data.map(row => row.foto));
    console.log(response.data);
  };

  const handleSave = async () => {
    console.log('Save gallery:', items);
  
    const axiosPromise = axios.post(`galerie/update?art=${art}`, {
      art,
      items,
    });
  
    toast.promise(axiosPromise, {
      loading: 'Update der Fotogalerie.',
      success: 'Fotogalerie erfolgreich aktualisiert.',
      error: 'Ein Fehler ist aufgetreten.',
    });
  
    try {
      const response = await axiosPromise;
      console.log('Save successful:', response.data);
    } catch (error) {
      console.error('Error saving gallery:', error);
    }
  };

  return (
    <Formik
      initialValues={{
        art: null
      }}

      onSubmit={
        (values) => {
          console.log("get images");
          getImages(values.art.value);
          getPhotos(values.art.value);
          setSelectedItems([]);
          setArt(values.art.value);
          setSelectedPhotos([]);
        }
      }
    >
      {
        props => {
          const {
            // values,
            // handleChange,
            handleSubmit,
            // handleBlur,
            // setFieldValue
          } = props;
          return (
            <>
              <Container>
                <br></br>
                <h3>Galerie (Admin Ansicht)</h3>
                <p style={{ color: "grey" }}>
                  Hier können Sie die Fotos für die Galerie verwalten. Wählen Sie eine Art aus und fügen Sie Fotos hinzu, verschieben die Fotos in eine andere Reihenfolge oder entfernen Fotos aus der Galerie.
                </p>
                <Form style={{ maxWidth: "400px" }} onSubmit={handleSubmit}>
                  <InputArt name="art" onChange={handleSubmit}></InputArt>
                </Form>
                <hr></hr>
                <Gallery
                  items={items}
                  setItems={setItems}
                  photos={photos}
                  setPhotos={setPhotos}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  art={art}
                  selectedPhotos={selectedPhotos}
                  setSelectedPhotos={setSelectedPhotos}
                />
                <br />
                {art !== null && (
                  <Button variant="primary" onClick={handleSave}>
                    Änderungen speichern
                  </Button>
                )}
                <br />
                <br />
              </Container>
            </>
          );
        }
      }
    </Formik>
  )
}
